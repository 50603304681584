import { useRef } from 'react'
import PropTypes from 'prop-types'
import './PaymentAccordion.css'
import NavItem from '../NavItem/NavItem'

const PaymentAccordion = ({
    handleToggle,
    active,
    closeFunction,
    isDisabled = false,
}) => {
    const userClasses = isDisabled
        ? `
    disabled
    fw-medium 
    text-lg 
    text-primary 
    mb-7 
    py-3 
    px-6 
    rounded-lg 
    transition-all 
`
        : `
    nav-item
    fw-medium 
    text-lg 
    text-primary 
    mb-7 
    hover:bg-accent 
    hover:text-white 
    py-3 
    px-6 
    rounded-lg 
    transition-all 
`

    const AccRef = useRef()
    return (
        <div className="lg:mb-3">
            <div className="lg:mt-12 rounded-lg overflow-auto  transition-all">
                <div className="acc-header hover:bg-accent hover:text-white">
                    <div
                        className={`acc-toggle  relative ${
                            active ? 'active text-white bg-accent' : ''
                        }`}
                        onClick={() => handleToggle()}
                    >
                        <h5 className="acc-mobile-title lg:acc-title lg:text-start text-center px-6 py-3 lg-0 relative">
                            Βεβαιώσεις - Ρυθμίσεις
                        </h5>
                    </div>
                </div>
                <div
                    ref={AccRef}
                    className={`collapse ${active ? 'show' : ''}`}
                    style={
                        active
                            ? { height: AccRef.current.scrollHeight - 40 } // <-- Correction needed, subtracting its not a valid sollution
                            : { height: '0px' }
                    }
                >
                    <div className="acc-body text-md">
                        <div className="flex flex-col">
                            <NavItem
                                classes={userClasses}
                                path="/debts/confirmed"
                                text="Βεβαιωμένες Οφειλές"
                                closeFunction={closeFunction}
                            />
                            <NavItem
                                classes={userClasses}
                                path="/debts/installments"
                                text="Οφειλές σε Ρύθμιση"
                                closeFunction={closeFunction}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

PaymentAccordion.propTypes = {
    handleToggle: PropTypes.func,
    active: PropTypes.bool,
    closeFunction: PropTypes.func,
    isDisabled: PropTypes.bool,
}

export default PaymentAccordion

import Modal from '../components/Modal/Modal'
import Header from '../components/Header/Header'
import HistoryTable from '../components/HistoryTable/HistoryTable'
import PopupMessage from '../components/PopupMessage/PopupMessage'
import Loading from '../components/Loading/Loading'
import StatusText from '../components/StatusText/StatusText'
import HistoryItem from '../components/mobile-components/HistoryItem/HistoryItem'
import MoreButton from '../components/TableButtons/MoreButton'

import { useState, useEffect, useContext } from 'react'
import { Link } from 'react-router-dom'
import { AuthContext } from '../contexts/AuthContext'
import { formatDate, sortByDate } from '../helpers/formatters'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import useAuth from '../hooks/useAuth'
import useMediaQuery from '../hooks/useMediaQuery'
import {
    getBalanceRequestsOfUser,
    createBalanceRequest,
} from '../api/balance-requests'

const BalanceRequest = () => {
    useEffect(() => {
        document.title =
            'Αίτηση Υπολοίπου - Πλατφόρμα Ηλ. Πληρωμών Δήμου Αντιπάρου'
    }, [])

    const isMobile = useMediaQuery('(max-width:1024px)')
    const [popup, setPopup] = useState({ message: '', type: '', shown: false })
    const { token } = useContext(AuthContext)
    const { getCurrentUserID } = useAuth()
    const userId = getCurrentUserID()
    const options = {
        headers: {
            Authorization: `Bearer ${token.access_token}`,
        },
    }
    const queryClient = useQueryClient()

    const { isLoading, data } = useQuery({
        queryKey: ['balanceRequestsHistory', userId, options],
        queryFn: () => getBalanceRequestsOfUser(userId, options),
    })

    const mutation = useMutation({
        mutationFn: () =>
            createBalanceRequest({
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token.access_token}`,
                },
            }),
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: ['balanceRequestsHistory'],
            })

            setPopup(values => ({
                ...values,
                shown: true,
                message: (
                    <p>
                        Η αίτηση σας έχει υποβληθεί. Θα ενημερωθείτε σύντομα για
                        την εξέλιξη της με σχετικό e-mail, καθώς και από τον
                        πίνακα &nbsp;
                        {/* Add space with html entity because linter does not let us add with text */}
                        <Link className="font-bold" to={'/balance-requests'}>
                            &quot;Αιτήσεις Υπολοίπου&quot;
                        </Link>
                    </p>
                ),
                type: 'success',
            }))
        },
        onError: () => {
            setPopup(values => ({
                ...values,
                shown: true,
                message: (
                    <>
                        <p>Η αίτησή σας δεν υποβλήθηκε.</p>
                        <p>Παρακαλώ προσπαθήστε ξανά</p>
                    </>
                ),
                type: 'error',
            }))
        },
    })

    if (isLoading) {
        return <Loading />
    }

    const headings = [
        'Τίτλος',
        'Ημερομηνία Υποβολής Αίτησης',
        'Κατάσταση',
        'Περισσότερα',
    ]

    const rows = data?.balanceRequests.map(request => {
        return {
            0: request.title ?? '-',
            1: formatDate(request.createdAt) ?? '-',
            2: (
                <StatusText
                    key={3}
                    kind="balance-requests"
                    status={request.status}
                />
            ),
            3: (
                <MoreButton
                    key={4}
                    kind={'balance-requests'}
                    requestID={request.id}
                />
            ),
        }
    })

    // Sort rows with time value
    const sortedRows = sortByDate(rows, 1)

    return (
        <>
            {popup.shown && (
                <PopupMessage
                    message={popup.message}
                    type={popup.type}
                    closePopUp={() =>
                        setPopup(values => ({ ...values, shown: false }))
                    }
                    showCloseButton={true}
                />
            )}

            {isMobile ? (
                <>
                    <h2 className="text-primary text-2xl text-center font-light mt-12 mb-6">
                        Αίτηση Υπολοίπου
                    </h2>

                    <div className="flex justify-between bg-white flex-col items-center gap-12 py-8 my-5 px-10 shadow-lg rounded-2xl">
                        <p className=" text-base text-primary text-center font-bold">
                            Μπορείς να ζητήσεις ενημέρωση για το υπόλοιπό σου με
                            ένα κλικ!
                        </p>

                        <button
                            className="bg-[#a2d8fd] hover:bg-white text-white hover:text-[#a2d8fd] border border-[#a2d8fd] transition-all font-bold rounded-lg py-3.5 px-5"
                            onClick={() => mutation.mutate()}
                            disabled={mutation.isLoading}
                        >
                            Αίτηση Υπολοίπου
                        </button>
                    </div>

                    <h2 className="text-primary text-3xl font-light mt-24 mb-6">
                        Ιστορικό Αιτήσεων
                    </h2>

                    {data.balanceRequests.length > 0 ? (
                        data.balanceRequests.map((request, index) => {
                            return (
                                <HistoryItem
                                    key={request.id}
                                    headings={headings}
                                    rows={sortedRows[index]}
                                />
                            )
                        })
                    ) : (
                        <h2 className="text-base text-primary font-bold text-center">
                            Δεν βρέθηκαν αιτήσεις
                        </h2>
                    )}
                </>
            ) : (
                <Modal classes="w-full max-w-7xl flex flex-col">
                    <Header
                        includeLogo={false}
                        withBorder={true}
                        includeButtons={true}
                    />

                    <div className="flex flex-col justify-start items-center grow p-6">
                        <h2 className="text-primary text-3xl font-light mt-12 mb-6">
                            Αίτηση Υπολοίπου
                        </h2>

                        <div className="flex justify-between items-center gap-12 py-8 px-10 shadow-lg rounded-2xl">
                            <p className="font-medium text-base text-primary">
                                Μπορείς να ζητήσεις ενημέρωση για το υπόλοιπό
                                σου με ένα κλικ!
                            </p>

                            <button
                                className="bg-[#a2d8fd] hover:bg-white text-white hover:text-[#a2d8fd] border border-[#a2d8fd] transition-all font-bold rounded-lg py-3.5 px-5"
                                onClick={() => mutation.mutate()}
                                disabled={mutation.isLoading}
                            >
                                Αίτηση Υπολοίπου
                            </button>
                        </div>

                        <h2 className="text-primary text-3xl font-light mt-24 mb-6">
                            Ιστορικό Αιτήσεων
                        </h2>

                        <HistoryTable
                            headings={headings}
                            rows={sortedRows}
                            noDataText="Δεν βρέθηκαν αιτήσεις"
                        />
                    </div>
                </Modal>
            )}
        </>
    )
}

export default BalanceRequest

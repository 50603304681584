export function validateEmail(email) {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
}

export function validatePassword(password, passwordConfirmation) {
    return password === passwordConfirmation && !/\s/.test(passwordConfirmation)
}

export function validateName(name) {
    // const nameRegex = /^[α-ωΑ-Ωάίϊΐώΰϋύέόή\s]+$/

    const nameRegex = /^[α-ωΑ-ΩάίϊΐώΰϋύέόήA-Za-z\s]+$/
    return name.match(nameRegex)
}

export function validateAfm(afm) {
    if (afm < 0) {
        return false
    }

    const vatNumber = String(afm)
    const countDigits = vatNumber.length
    const array = vatNumber.split('')

    let i = 0
    let sumOfDigits = 0
    for (i = 0; i < 9; i++) {
        sumOfDigits = sumOfDigits + Number(vatNumber[i])
    }

    if (countDigits !== 9 || sumOfDigits === 0) {
        return false
    } else {
        let m = 256
        let a1 = 0
        let i = 1
        for (i = 0; i < 8; i++) {
            a1 = a1 + Number(array[i]) * m
            m = m / 2
        }
        const a2 = a1 % 11

        if (Number(array[8]) === a2 % 10) {
            return true
        } else {
            return false
        }
    }
}

/* eslint-disable react/prop-types */
// Anousio na mpei to prop types

const FormBodyMobile = props => {
    const line = (text, type, name, euro) => (
        <div className="flex w-full justify-between gap-3 border-b border-gray px-4 pb-1 pt-4 mr-5">
            <div className="flex justify-between w-2/6">
                <p className="font-semibold">{text}</p>
                {euro && <p className="font-semibold">€</p>}
            </div>
            <input
                className="self-end text-secondary flex-1 w-2/3"
                placeholder="Εισαγωγή.."
                name={name}
                type={type}
                min={0}
                onChange={props.handleChange}
            />
        </div>
    )

    return (
        <form onSubmit={props.submitHandler}>
            <div className="w-full flex flex-col">
                {line('Κωδικός Οφειλής', 'text', 'paymentCode')}
                {line('Αιτιολογία', 'text', 'description')}
                {line('Ποσό', 'number', 'amount', true)}
                <div className="self-center py-10">
                    <button
                        className="w-fit py-2 px-8 bg-primary text-white border border-primary hover:bg-white hover:text-primary transition-all rounded-lg "
                        type="submit"
                    >
                        Πληρωμή
                    </button>
                </div>
            </div>
        </form>
    )
}

export default FormBodyMobile

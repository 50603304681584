import { useState } from 'react'
import { useEffect } from 'react'
import useAuth from '../../../hooks/useAuth'
import NavItem from '../../NavItem/NavItem'
import PaymentAccordion from '../../PaymentAccordion/PaymentAccordion'

const BurgerMenu = () => {
    const [menuOpen, setMenuOpen] = useState(false)
    const [active, setActive] = useState(false)

    const handleToggle = () => {
        setActive(!active)
    }
    const { logout, isAdmin } = useAuth()

    const toggleMenu = () => {
        setActive(false)
        setMenuOpen(!menuOpen)
    }

    useEffect(() => {
        const menuHeader = document.getElementById('mobile-header')
        const profileButton = document.getElementById('profileBtn')
        const closeMenu = () => {
            setMenuOpen(false)
        }
        if (!menuOpen) {
            if (isAdmin()) {
                menuHeader.classList.remove('fixed')
                menuHeader.classList.add('rounded-b-[30px]')
            } else {
                menuHeader.classList.remove('fixed', 'bg-white')
            }
        } else {
            if (isAdmin()) {
                menuHeader.classList.add('fixed')
                menuHeader.classList.remove('rounded-b-[30px]')
            } else {
                menuHeader.classList.add('fixed', 'bg-white')
                menuHeader.classList.remove('bg-[#f0f3f4]')
                if (profileButton) {
                    profileButton.addEventListener('click', closeMenu)
                }
            }
        }
        return () => {
            if (profileButton) {
                profileButton.removeEventListener('click', closeMenu)
            }
        }
    }, [menuOpen])

    return (
        <>
            <button onClick={toggleMenu}>
                <span className="font-bold text-5xl" aria-hidden="true">
                    {menuOpen ? (
                        isAdmin() ? (
                            <div className="-space-y-1">
                                <div className="w-8 h-1 bg-white rotate-45"></div>
                                <div className="w-8 h-1 bg-white -rotate-45"></div>
                            </div>
                        ) : (
                            <div className="-space-y-1">
                                <div className="w-8 h-1 bg-accent rotate-45"></div>
                                <div className="w-8 h-1 bg-accent -rotate-45"></div>
                            </div>
                        )
                    ) : isAdmin() ? (
                        <div className="space-y-2">
                            <div className="w-8 h-1 bg-white"></div>
                            <div className="w-8 h-1 bg-white"></div>
                        </div>
                    ) : (
                        <div className="space-y-2">
                            <div className="w-8 h-1 bg-accent"></div>
                            <div className="w-8 h-1 bg-accent"></div>
                        </div>
                    )}
                </span>
            </button>

            {menuOpen ? (
                <>
                    <div
                        className={`fixed h-[calc(100%-122px)] overflow-y-auto w-full z-10 left-0 top-[122px] ${
                            isAdmin() ? 'bg-primary' : 'bg-white'
                        } h-full`}
                    >
                        <div className="flex flex-col items-center">
                            <ul className="flex gap-8 flex-col items-center justify-start py-10">
                                {isAdmin() ? (
                                    <>
                                        <NavItem
                                            classes="font-bold text-2xl text-white"
                                            path="/admin/payment"
                                            text="Πληρωμές Οφειλών"
                                            closeFunction={toggleMenu}
                                        />
                                        <NavItem
                                            classes="font-bold text-2xl text-white"
                                            path="/admin/balance-request"
                                            text="Αιτήσεις Υπολοίπου"
                                            closeFunction={toggleMenu}
                                        />
                                    </>
                                ) : (
                                    <>
                                        <NavItem
                                            classes="font-bold text-2xl text-center"
                                            path="/balance-requests"
                                            text="Αίτηση Υπολοίπου και ιστορικό αιτήσεων"
                                            closeFunction={toggleMenu}
                                        />
                                        <NavItem
                                            classes="font-bold text-2xl"
                                            path="/payments"
                                            text="Ιστορικό Πληρωμών"
                                            closeFunction={toggleMenu}
                                        />
                                        <NavItem
                                            classes="font-bold text-2xl"
                                            path="/payment"
                                            text="Άμεση Πληρωμή"
                                            closeFunction={toggleMenu}
                                        />
                                        <PaymentAccordion
                                            handleToggle={handleToggle}
                                            closeFunction={toggleMenu}
                                            active={active}
                                        />
                                    </>
                                )}

                                <button
                                    className={`font-bold text-2xl ${
                                        isAdmin() ? 'text-white' : ''
                                    }`}
                                    onClick={logout}
                                >
                                    Αποσύνδεση
                                </button>
                            </ul>
                        </div>
                    </div>
                </>
            ) : null}
        </>
    )
}

export default BurgerMenu

/* eslint-disable react/prop-types */
// Anousio na mpei to prop types
import FormField from '../FormField/FormField'

const FormBodyDesktop = props => {
    return (
        <div className="px-10 lg:px-16 py-8 flex-1 h-full flex flex-col  justify-start">
            <form
                className="payment-form flex flex-col items-between"
                onSubmit={props.submitHandler}
            >
                <FormField
                    type="text"
                    label="Κωδικός Οφειλής:"
                    name="paymentCode"
                    onChange={props.handleChange}
                />

                <FormField
                    type="text"
                    label="Αιτιολογία:"
                    name="description"
                    onChange={props.handleChange}
                />

                <FormField
                    type="number"
                    label="Ποσό:"
                    name="amount"
                    includeEuro={true}
                    onChange={props.handleChange}
                />

                <button
                    className="bg-primary text-white border border-primary hover:bg-white hover:text-primary transition-all rounded-lg w-fit py-3.5 px-10 self-center"
                    type="submit"
                >
                    Πληρωμή
                </button>
            </form>
        </div>
    )
}

export default FormBodyDesktop

import './NavItem.css'

import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'

const NavItem = ({ path, text, classes, closeFunction }) => {
    return (
        <li className={classes}>
            <NavLink onClick={closeFunction} to={path}>
                {text}
            </NavLink>
        </li>
    )
}

NavItem.propTypes = {
    path: PropTypes.string,
    text: PropTypes.string,
    classes: PropTypes.string,
    closeFunction: PropTypes.func,
}

export default NavItem

import _fetch from '.'

export async function getProfile(options = {}) {
    const url = `${process.env.REACT_APP_API_URL}/users/me`
    return _fetch(url, options)
}

export async function updateProfile(options = {}) {
    const url = `${process.env.REACT_APP_API_URL}/users/me`
    return _fetch(url, options)
}

import './HomeLoggedOut.css'

import Header from '../Header/Header'

import useMediaQuery from '../../hooks/useMediaQuery'

import TopPart from './TopPart'
import MobileTopPart from './MobileTopPart'

const HomeLoggedOut = () => {
    const isMobile = useMediaQuery('(max-width:1024px)')

    return (
        <>
            {isMobile ? null : (
                <Header
                    includeLogo={true}
                    withBorder={false}
                    includeButtons={true}
                />
            )}

            {isMobile ? <MobileTopPart /> : <TopPart />}

            <div className="w-full flex justify-center items-center bg-white p-12 flex-col">
                <div className="bg-aqua aqua-box p-8 text-center text-white font-bold max-w-5xl text-lg">
                    <p>
                        Η πλατφόρμα GovPay του Δήμου Αντιπάρου είναι σε πιλοτική
                        λειτουργία. Οι πολίτες και οι φορείς μπορούν να
                        συνδεθούν μέσω taxisnet, για να προχωρήσουν στην πληρωμή
                        των οφειλών τους.
                    </p>
                </div>
                <p className="text-center text-2xl py-12 font-bold my-3">
                    Μπορείτε να πληρώσετε με ασφάλεια και ηλεκτρονικά
                </p>
                <div className="w-full max-w-3xl mb-8">
                    <ul className="list-disc text-xl flex flex-col gap-3">
                        <li>Τέλη Παρεπιδημούντων</li>
                        <li>Τέλη χρήσης κοινόχρηστων χώρων</li>
                        <li>Λογαριασμός Ύδρευσης</li>
                        <li>
                            Μισθώματα ακινήτων, βοσκοτόπων, λατομείων και
                            δασικών εκτάσεων
                        </li>
                        <li>Δικαίωμα ανακομιδής και χρήσης οστεοφυλακίου</li>
                        <li>Αγορά τάφου</li>
                    </ul>
                </div>
            </div>
        </>
    )
}

export default HomeLoggedOut

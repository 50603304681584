import { useId } from 'react'
import PropTypes from 'prop-types'

const FormField = ({
    type,
    name,
    label,
    labelOnSide = true,
    classes,
    onChange,
    value,
    onBlur,
    autoComplete,
    includeEuro,
}) => {
    const id = useId()

    return (
        <>
            {labelOnSide ? (
                // LABEL WILL BE ON LEFT SIDE
                <label
                    className={`text-base text-right text-primary mb-14 flex justify-end gap-3 items-center ${
                        classes ?? ''
                    }`}
                    htmlFor={`${id}-${name}`}
                >
                    {label}
                    <div className="flex gap-3 w-2/3 border-b border-gray-300">
                        {includeEuro && (
                            <p className="font-semibold text-md">€</p>
                        )}
                        <input
                            id={`${id}-${name}`}
                            type={type}
                            name={name}
                            className="w-full"
                            onChange={onChange}
                            onBlur={onBlur}
                            defaultValue={value}
                            autoComplete={autoComplete}
                            min={0}
                        />
                    </div>
                </label>
            ) : (
                // LABEL WILL BE ON TOP
                <label
                    className={`text-base text-left text-primary mb-12 ${
                        classes ?? ''
                    }`}
                    htmlFor={`${id}-${name}`}
                >
                    <div className="text-base font-bold">{label}</div>
                    <input
                        id={`${id}-${name}`}
                        type={type}
                        name={name}
                        className="border-b border-gray-300 w-full mt-4"
                        onChange={onChange}
                        onBlur={onBlur}
                        defaultValue={value}
                        autoComplete={autoComplete}
                        min={0}
                    />
                </label>
            )}
        </>
    )
}

FormField.propTypes = {
    type: PropTypes.string,
    name: PropTypes.string,
    label: PropTypes.string,
    labelOnSide: PropTypes.bool,
    classes: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    value: PropTypes.string,
    autoComplete: PropTypes.string,
    includeEuro: PropTypes.bool,
}

export default FormField
